import {WEBSERVER} from "../services/globals";
import SearchComponent from '../components/search';
import User from "../components/user";
import React, { useState } from "react";
import { Queries } from "../services/queries";
import {ICart} from "../components/contracts/types";


 export default function MenuComponent({props,countx}) {

/*
  let mycount = 0;

  const [count, setCount] = useState(getstartcount());

  function getstartcount () {
    const queries = new Queries();
    const activeOrder = queries.getCartCount() as ICart; 
    console.log("getstartcount activeOrder:" +  JSON.stringify(activeOrder));

    if ((typeof activeOrder !== 'undefined') && (activeOrder))  {
       mycount =  activeOrder?.lines.length;
      }
//       console.log("getstartcount:" +  mycount);

     return mycount;
  }
  */ 
  //console.log("++++ props: " + JSON.stringify({props}));
   let out="";
   let base = WEBSERVER + "/category/";
   out += `<nav class="navbar navbar-expand-md" aria-label="navbar">
              <div class="container-fluid">
             <a class="navbar-brand" href="`+ base + `.."><img src="` + base + `../logo.png" alt="go home"></a>`;
  
    out += `<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarsExample04">
        `;
  
    out += '<ul class="navbar-nav me-auto mb-2 mb-md-0">';
    
    for (let item of props.collections.items) {
      if (item.parent.id == 1) {
          if (item.children.length  > 0) {
            out += `<li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="dropdown-` + item.slug + `" data-bs-toggle="dropdown" aria-expanded="false">`  + item.name + `</a>
                      <ul class="dropdown-menu" aria-labelledby="dropdown-` + item.slug + `">`;
  
            for (let child of item.children) {
              out += '<li class ="dropdown-item"><a href="' + base + child.slug + '">' + child.name + '</a></li>';
            }
            out += "</ul>";
          } else {
            out += '<li class="nav-item"><a class="nav-link active" href="' + base + item.slug + '">' + item.name + '</a>';
          }     
          out += "</li>";
      }
    }
    
    out += "</ul></div></nav>";
  //console.log(out);
  //Hallo{ebene1.map((id) => (<li>{id}</li>) )}
   return  (<div className="container-fluid">
    <div className="row">
    <div className="col" dangerouslySetInnerHTML={{__html: out}}></div>
    <div className="col searchframe">
    <SearchComponent></SearchComponent>
    </div>
    <div className="col order-5 userandcart">
      <div className="flex-container">
        <div className=""><User></User></div>
        <div className="">
          <a href="../cart"><div>{countx}<i className="bi-cart"></i></div></a>
        </div>    
      </div>
    </div>
  </div>
  </div>
);
  
 };
 
