import Head from 'next/head'
import Link from 'next/link';
import User from "../components/user";


import MenuComponent from '../components/menu';
import { GetStaticProps } from "next";
import { ApolloClient, InMemoryCache, gql, useQuery } from "@apollo/client";
import PlaceCartComponent from '../components/placecart'
import { IArticle, SearchInput, facetValueFilterInput} from "../components/contracts/types";


import React, { useState, useEffect } from "react";
import { Queries } from "../services/queries";
import Soffer from '../components/soffer'

import {WEBSERVER, APISERVER, imgpath, numberToCurrency} from "../services/globals"

//  query SearchProducts($term: String!, $skip: Int, $take: Int) {

const SEARCH_PRODUCTS = gql`
  query SearchProducts($input: SearchInput!) {
  search(input: $input) {
    totalItems
    facetValues {
      count
      facetValue {
        id
        name
        facet {
          id
          name
        }
      }
    }
    items {
      productName
      slug
      description
      sku
      productAsset {
        id
        preview
      }
      productVariantName        
      facetIds
      facetValueIds  

      priceWithTax {
        ... on SinglePrice {
          value
        }
        ... on PriceRange {
          min
          max
        }
      }
      currencyCode
    }
  }
}`;



export default function Home({categories}) {


  const [count, setCount] = useState<number | null>(0);
    
  useEffect(() => {
    async function getstartcount ()  {
      let mycount : number = -1;
      const queries = new Queries();
      mycount = await queries.getCartCount();
//        console.log("getstartcount: " + mycount);
      setCount(mycount);
    }
    getstartcount();
  }, []);


  function addCart() {
    if (count)
      setCount(count + 1);
    else {
      setCount(1);
    }
  }


  const { data, loading, error } = useQuery(
    SEARCH_PRODUCTS,
    {variables: {
      input : {
        "facetValueFilters": [
          {"or": [41,42]}, //top
          ],
        "take": 10,
        "groupByProduct" : true
      }
    }
});

  if (!data) return;

  let list = [];

  for (let item of data.search.items) {
    let article:IArticle = {
      hid :"",
      id :"",
      hname  :"",
      name  :"",
      slug :"",
      hdescription : "",
      description :"",
      img :"",
      price :"",
      soffer :"",
      vcount : 0
    };
    
    console.log(JSON.stringify(item));

    article.id = item.productVariantId;
    article.hname = item.productName;
    article.hdescription = item.description;
    article.img = imgpath(item.productAsset.preview);
    article.slug = item.slug;
    article.price =  "ab " + numberToCurrency(item.priceWithTax.min);

    list.push(article);
    console.log(item.productName);
  }

  function detailurl(slug:String) {
    return WEBSERVER + "/items/" + slug;
  }
  const overlay : string = "../soffer.png";

  return (
    <div className="">
      <Head>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <MenuComponent props={categories} countx={count}></MenuComponent>
      <div className="container-fluid">
      <div className="row">

      {list.map((elem) => (
              
              <div key={elem.id} className="product col-md-4">
               <a href={detailurl(elem.slug)}>
               <h4>{elem.hname}</h4> 
               <div className="h-25 boxheight"><small dangerouslySetInnerHTML={{__html:elem.hdescription}}></small></div></a>
               <a href={detailurl(elem.slug)}><div className="img-container"><img src={elem.img} className="img-fluid"></img>
               <Soffer type="top"></Soffer></div>
               </a> <span>{elem.price}</span>
               <div className="d-flex flex-row justify-content-between margin-buy-box">
                  <div><a className="btn btn-info" href={detailurl(elem.slug)}>Details</a></div>
                  <div>  </div>
                </div>
              </div>
              
          ))}

      </div>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <div>
          Build with <a href="https://nextjs.org"  target="__new">Next.js</a> and <a href="https://vendure.io" target="__new">Vendure</a> 
        </div>
      </div>
    </div>
  )
};



export const getStaticProps: GetStaticProps = async (context) => {
  //  console.log("+++++++ " + context);
    const queries = new Queries();
    const categories = await queries.getCategories(); 
  //  console.log("+++++++------ " + JSON.stringify(categories));
    return {
      props: {
        categories: categories
      },
    };
  };


