import React from "react";


 export default function Soffer(offer) {

  //console.log("###### "  + JSON.stringify(offer));

  if ((offer.type == "top") || (offer.type == "offer")) {
  return  (
  <div>
    <img src="../soffer.png" className="overlay-img"></img>
  </div>
  ); 
  } else {
    return (<div className="dummy" ></div>)

  }
 
};
 
