import {WEBSERVER} from "../services/globals";


 export default function SearchComponent() {

  const hc = function handleClick() {
    const f = document.getElementById('searchbox');
  }

   return  (<div>
      <form method="get" id="searchbox" action="/searchresult">
        <div className="input-group mb-3" id="search-input-form">
          <button  type="submit" className="input-group-text" id="basic-addon1"> <i className='bi bi-search'></i></button>
          <input type="text" name="q"  placeholder="Suche" className="form-control"  aria-describedby="basic-addon1">
          </input>
        </div>
      </form>
   </div> );
  
 };
 
